function federatedSearchBlocUi(results = [], tracking = {}, source) {
  const logFactory = GlobalSite.checkDependency('GlobalSite.logFactory');
  const inbentaApi = GlobalSite.checkDependency('GlobalSite.inbentaApi');
  const Log = logFactory('answerBlockHandler.js');
  const $root = $('.inbenta-more-results');
  const $showMoreBtnExtra = $('.more-results-btn');
  const showMoreMessageInitExtra = $showMoreBtnExtra.find('button').text();
  const nbItemExtra = results.length;
  const $itemsExtra = $('.more-results-list');
  let $itemExtra;

  function template() {
    return results
      .map((suggestion, index) => {
        return `<li class="more-results-item ${index > 4 ? 'more-results-item--hidden' : ''}">
                  <a href="${suggestion?.attributes?.URL || ''}" 
                  data-source="${source}"
                  data-tracking-type="external_click" 
                  data-tracking-external-value="${suggestion?.tracking?.externalCode || ''}"
                  data-tracking-search-value="${tracking?.searchCode || ''}" 
                  data-tracking-click-value="${suggestion?.tracking?.clickCode || ''}" 
                  aria-label="${suggestion.title}">
                      ${suggestion.title}
                  </a>
                </li>`;
      })
      .join('');
  }

  (function render() {
    $itemsExtra.html(template());
    $itemExtra = $('.more-results-item');
    if (nbItemExtra === 0) {
      $root.addClass('hide');
    } else if (nbItemExtra <= 5) {
      $showMoreBtnExtra.hide();
      $root.removeClass('hide');
    } else {
      $root.removeClass('hide');
    }
  })();

  $itemExtra.on('click', (e) => {
    if (inbentaApi.isTracking()) {
      e.preventDefault();
      const $this = $(e.target);
      const source = $this.data('source');
      const trackingType = $this.data('trackingType');
      const trackingExternalValue = $this.data('trackingExternalValue');
      const trackingSearchValue = $this.data('trackingSearchValue');
      const trackingClickValue = $this.data('trackingClickValue');
      if (!trackingType || !trackingExternalValue || !trackingSearchValue || !trackingClickValue) {
        return;
      }
      inbentaApi.sendTrackingEvents(
        {
          type: trackingType,
          data: { externalCode: trackingExternalValue, searchCode: trackingSearchValue },
        },
        source,
      );
      inbentaApi.sendTrackingEvents(
        { type: 'click', data: { code: trackingClickValue } },
        source,
        'search',
      );
      window.location.replace($this.attr('href'));
      Log.log({
        trackingType,
        trackingExternalValue,
        trackingSearchValue,
        trackingClickValue,
        source,
      });
    }
  });

  $showMoreBtnExtra.on('click focus', (e) => {
    $itemExtra.slice(5, nbItemExtra).toggleClass('more-results-item--hidden');

    if ($itemExtra.is('.more-results-item--hidden')) {
      if (e.originalEvent.detail === 0) {
        $('.more-results-item:nth-child(6) a').blur();
      }
      $showMoreBtnExtra.find('button').text(showMoreMessageInitExtra);
    } else {
      $showMoreBtnExtra.find('button').text('Afficher moins de résultats');
      if (e.originalEvent.detail === 0) {
        $('.more-results-item:nth-child(6) a').focus();
      }
    }
  });
}

(function federatedSearchHandler() {
  window.GlobalSite = window.GlobalSite || {};

  const exportToGlobalSite = GlobalSite.checkDependency('GlobalSite.exportToGlobalSite');

  exportToGlobalSite({
    federatedSearchBlocUi,
  });
})();
